import { defineComponent, ref } from '@vue/composition-api';
import type { Ref } from '@vue/composition-api';

import PageNodeMain from '@/components/PageNodeMain';
import PageNodeSub from '@/components/PageNodeSub/index.vue';
import Card from '@/components/Card';
import SearchLite from '@/components/Article/SearchLite';
import FlashHot from '@/components/Article/FlashHot';
import FlashLab from '@/components/Article/FlashLab';
import FlashAuthorLite from '@/components/Article/FlashAuthorLite';
import FlashBarLite from '@/components/Article/FlashBarLite/index.vue';

import { Btn, Tab } from '@/components/UI';

//const FlashAuthorLite = () =>
//    import('@/components/Article/FlashAuthorLite/index.vue').catch(() => {
//        /**/
//    });

export default defineComponent({
	components: {
		PageNodeMain,
		PageNodeSub,
		Card,
		Btn,
		Tab,
		SearchLite,
		FlashHot,
		FlashLab,
		FlashAuthorLite,
		FlashBarLite,
	},
	setup(props: any, { root }: any) {
		const { $route, $router } = root;
		const t = root.$t.bind(root);
		const pushroute = (path: string) =>
			$router
				.push({
					path,
				})
				.catch(() => {
					/**/
				});
		const navigation: Ref<any[]> = ref([
			{
				key: 'all',
				title: '综合',
				action: () => pushroute('/flash'),
				isActive: true,
			},
			{
				key: 'mv',
				title: 'MV',
				action: () => pushroute('/flash/category/mv'),
				isActive: true,
			},
			{
				key: 'cartoon',
				title: '动画',
				action: () => pushroute('/flash/category/cartoon'),
				isActive: true,
			},
			{
				key: 'game',
				title: '小游戏',
				action: () => pushroute('/flash/category/game'),
				isActive: false,
			},
			{
				key: 'postcards',
				title: '贺卡',
				action: () => pushroute('/flash/category/postcards'),
				isActive: true,
			},
			{
				key: 'material',
				title: 'Flash素材',
				action: () => pushroute('/flash/category/material'),
				isActive: false,
			},
		]);
		const navigationDefaultKey = (() => {
			/*
            const sMat = '' + $route?.matched[$route.matched.length - 1]?.path;
            const area = sMat.substring(sMat.lastIndexOf('/') + 1);
            //console.log(navigation.value.find(item => item.key === area)?.key);
            return navigation.value.find(item => item.key === area)?.key;
            */
			return $route.params.onlyid || 'all';
		})();

		return { navigation, navigationDefaultKey, t };
	},
});
