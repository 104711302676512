import store from '@/store';
import {
    defineComponent,
    onMounted,
    ref,
    Ref,
    watch,
} from '@vue/composition-api';

export default defineComponent({
    setup() {
        const opus: Ref<any[]> = ref([
            {
                onlyid: 'a75e70dd78ab809c',
                title: '挥着翅膀的女孩',
            },
            {
                onlyid: 'uid78we478ujh',
                title: '你那里下雪了吗',
            },
        ]);
        const lengthTwo = (arr: any[]) => {
            let arr1 = [...arr];
            if (arr1.length > 2) {
                arr1 = arr1.splice(0, 2);
            }
            return arr1;
        };
        onMounted(() => {
            //
        });
        watch(
            () => store.state.flashInfo,
            value => {
                const arrow = value?.arrow || [];
                opus.value = arrow;
            },
            { immediate: true, deep: true }
        );
        return { opus, lengthTwo };
    },
});
