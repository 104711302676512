import { getArea } from '@/api';
import { defineComponent, onMounted, ref, Ref } from '@vue/composition-api';

import { Empty, Skeleton } from '@/components/UI';

interface DataList {
	onlyid: string;
	title: string;
	playCount: number;
}

export default defineComponent({
	components: { Empty, Skeleton },
	setup(props, { root }: any) {
		const t = root.$t.bind(root);
		//const { $message } = root;
		const isLoading: Ref<boolean> = ref(true);
		const isError: Ref<boolean> = ref(false);
		const errorMsg: Ref<string> = ref('');
		const dataList: Ref<DataList[]> = ref([
			{
				onlyid: 'aduqwe67',
				title: '占位一111111',
				playCount: 30,
			},
			{ onlyid: 'aduqwe68', title: '占位二', playCount: 16 },
		]);
		onMounted(() => {
			getArea('FLASH_HOT')
				.then((data: any) => {
					dataList.value = data;
				})
				.catch(error => {
					isError.value = true;
					errorMsg.value = '' + error;
				})
				.finally(() => {
					isLoading.value = false;
				});
		});
		return { dataList, isLoading, isError, errorMsg, t };
	},
});
