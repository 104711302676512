import { defineComponent, ref } from '@vue/composition-api';
import type { Ref } from '@vue/composition-api';

import { Btn } from '@/components/UI';

export default defineComponent({
    name: 'FlashLab',
    components: { Btn },
    setup(props, { root }: any) {
        const { $message, $router } = root;
        const btnList: Ref<any[]> = ref([
            {
                title: '运行本地文件(.swf)',
                path: '/flash/lab/run-localhost',
            },
            {
                title: 'ActionScript代码调试',
                path: '/flash/lab/action-script-test',
            },
            {
                title: 'Fanvas转换工具', //'Canvas/WebGL转换',
                path: '/flash/lab/fanvas',
            },
            {
                title: 'FLV解码工具',
                path: '/flash/lab/flv-js',
            },
        ]);
        const toTargetPage = (path: string | undefined): void => {
            if (path) {
                $router
                    .push({
                        path,
                        query: {
                            _hash: Math.floor(
                                Math.random() * 16777215
                            ).toString(16),
                        },
                    })
                    .catch(function () {
                        /**/
                    });
            } else {
                $message.warning('由于你长得太帅了，本功能暂不开放');
            }
        };
        return {
            btnList,
            toTargetPage,
        };
    },
});
