import { defineComponent, reactive, ref, watch } from '@vue/composition-api';

import { Btn } from '@/components/UI';
import type { Ref } from '@vue/composition-api';

import noAvatarPng from './no-avatar.png';

import store from '@/store';

export default defineComponent({
	components: { Btn },
	setup() {
		//console.log(noAvatarPng);
		const author = reactive({
			//name: '仙娥',
			//qq: '827031310',
			//mail: 'i@sheaneh.com',
			//url: 'https://sheaneh.com',
			name: '',
			qq: '',
			mail: '',
			url: '',
		});
		const authorImg: Ref<string> = ref('' + noAvatarPng);
		watch(
			() => store.state.flashInfo,
			value => {
				author.name = value.author;
				author.qq = value.authorInfo.qq;
				author.mail = value.authorInfo.mail;
				author.url = value.authorInfo.url;
				if (value.authorInfo.img) {
					authorImg.value = value.authorInfo.img;
				} else if (value.authorInfo.qq) {
					authorImg.value = `https://q1.qlogo.cn/g?b=qq&nk=${author.qq}&s=100`;
				} else {
					authorImg.value = '' + noAvatarPng;
				}
			},
			{ immediate: true, deep: true }
		);
		return { author, authorImg };
	},
});
